import React from 'react'

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import Gallery from 'react-photo-gallery'
import { Link, withPrefix } from 'gatsby'
import Navigation from '../components/navigation'

import withLayout from '../components/layout'

const IndexPage = () => {
  const photos = []
  for (let i = 1; i <= 15; i++) {
    photos.push({
      src: withPrefix(`/images/painting_${i}.jpg`),
      width: 4,
      height: 3,
    })
  }

  const columns = containerWidth => {
    let columns = 1
    if (containerWidth >= 300) columns = 3

    if (containerWidth >= 700) columns = 4
    if (containerWidth >= 800) columns = 5
    return columns
  }

  return (
    <>
      <header>
        <div className="inline">
          <Navigation />
          <div id="title">
            <h1>Wim van der Veer</h1>
            <h2>
              <FormattedMessage id="subtitle" />
            </h2>
          </div>
          <div id="avatar"><span /></div>
        </div>
      </header>
      <main>
        <section className="info inline">
          <FormattedHTMLMessage id="introduction" />
        </section>

        <section className="inline">
            <Link to="/gallery">
                <Gallery photos={photos} direction="column" columns={columns}  />
            </Link>
        </section>
      </main>
    </>
  )
}
const customProps = {
  localeKey: 'home', // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(IndexPage)
